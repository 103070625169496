import {postRequest, getRequest} from "../../_Api/helper"

export const userDetailAction = (data) => {
    return getRequest('api/Utility/GetMe',data).then(res=>{return res.data});
}

export const sendEmailAction = (data) => {
    return postRequest('api/Utility/SendEmailPost',data);
}

export const getDefaultFiltersByTypeAction = (data) => {
    return getRequest('api/Parameter/GetDefaultFiltersByType',data);
}

export const getRestaurantsListAction = (data,paginationObj) => {
    return getRequest('api/Client/GetLocations',data,paginationObj);
}

export const getGoogleAPIListAction = (paginationObj) => {
    return getRequest('api/Client/GetAPILocations', paginationObj);
}

export const getRestaurantsByIdMenuListAction = (id, data, favorites) => {
    return getRequest(`api/Menu/GetFilteredMenuById/${id}`, data);
}

export const getMenuDetailsByIdAction = (data) => {
   return getRequest('api/Menu/GetFilteredMenuItemById',data);
}

export const getAllDefaultFiltersAction = (data) => {
    return getRequest('api/Parameter/GetDefaultFilters',data);
}

export const getAllCuisinesAction = (data) => {
    return getRequest('api/Menu/GetCuisines',data);
}


export const getRestaurantsDetailsByIdAction = (id,data) => {
    return getRequest(`api/Client/GetClientById/${id}`,data);
}


export const getLocationByIdAction = (id,data) => {
    return getRequest(`api/Client/GetLocationById/${id}`,data);
}

export const getLocationByFavoritesAction = (id, data) => {
    return getRequest(`api/Client/GetLocationByFavorites/${id}`, data);
}

export const addMyMealAction = (data) => {
    return postRequest(`api/MyMeal/AddMyMeal`,data, null, "params");
}

export const getMyMealAction = (data) => {
    return getRequest(`api/MyMeal/GetMyMeal`,data);
}

export const editMyMealAction = (data) => {
    return postRequest(`api/MyMeal/EditMyMeal`,data, null, "params");
}

export const deleteMyMealAction = (data) => {
    return postRequest(`api/MyMeal/DeleteMyMeal`,data, null, "params");
}
export const allRemoveMyMealAction = (data) => {
    return postRequest(`api/MyMeal/ClearMyMeal`,data, null, "params");
}

export const termConditionsAction = (data) => {
    return getRequest(`api/Utility/GetDisclaimer`,data);
}

export const clearAllPrefrencesAction = (data) => {
    return postRequest(`api/MyMeal/ClearMyMeal`,data);
}
