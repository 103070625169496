import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { getAllDefaultFiltersAction, getRestaurantsByIdMenuListAction, getLocationByFavoritesAction } from '../FrontEnd/Actions/Utility.action';
import { appConstants } from '../_Constants';
import toaster from './Toaster';
import { GetFirstName } from '../_Common/CommonComponent';
import $ from "jquery";
import { getRestaurantsListAction } from '../FrontEnd/Actions/Utility.action';

const SidebarFilter = ({ history, filterButton, distanceBtn, distanceFilter, className, parantFun, setLoader, addMyMealButton }) => {
    const { bFavorites } = useParams();
    const { iMenuId } = useParams();
    const { locationId } = useParams();
    const [formData, setForm] = useState({});
    const [allergen, setAllergen] = useState([]);
    const [keto, setKeto] = useState([]);
    const [planet, setPlanet] = useState([]);
    const [nutrition, setNutrition] = useState([]);
    const [vegetarianVegan, setVegetarianVegan] = useState([]);
    const [favoriteRecipes, setFavorites] = useState([]);
    const [preferenceList, setPreferenceList] = useState([]);
    const [restaurantsList, setRestaurantsList] = useState([]);
    const [tempData, setTempData] = useState({
        allergen: [],
        planet: [],
        nutrition: {},
        vegetarianVegan: [],
        keto: [],
        favoriteRecipes: [],
        count: 0
    });
    const [isFavorites, setIsFavorites] = useState(false);
    const [nutritionRange, setNutritionRange] = useState(appConstants.nutritionRange);
    const [notFilter, setNoFilter] = useState(false);

    /* Get Restaurants List */
    const getRestaurantsList = async (callPage = false) => {
        let data = {};
        var paginationObj = JSON.parse(localStorage.getItem('filterOption'));
        paginationObj.iLocationID = locationId;
        const res = await getRestaurantsListAction(paginationObj);

        let status = res && res.status;
        if (status === 200)
        {
            if (res.data.List.length > 0)
            {
                if (res.data.List[0].OtherLocations.length > 0)
                {
                    res.data.List.push.apply(res.data.List, res.data.List[0].OtherLocations);
                }
                //{
                    setRestaurantsList(res.data.List);
                //}
            }

            setTimeout(function () {
                setLoader(false);
            }, 1000)



        } else {
            setLoader(false);
            toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
        }



    }



    /**
     * [Remove preFrence]
     * @param  {string} value [Filter Data]
     * @param  {string} name  [Name of preFrence]
     */

    const removepreFrence = (value, name, objValue = '') => {
        if (typeof tempData[name] === 'object' && tempData[name].length === undefined) {
            if (Object.keys(tempData[name]).indexOf(value) === -1) {
                tempData[name][value] = objValue;
            } else if (Object.keys(tempData[name]).indexOf(value) >= 0) {
                let state = { ...tempData[name] };
                delete state[value];
                tempData[name] = state;
            }
        } else {
            if (tempData[name].indexOf(value) === -1) {
                tempData[name].push(value);
            } else if (tempData[name].indexOf(value) >= 0) {
                let state = [...tempData[name]];
                state.splice(state.indexOf(value), 1);
                tempData[name] = state;
            }
        }
        localStorage.setItem("tempData", JSON.stringify(tempData))
        setTempData({ ...tempData });
    }

    /**
     * [get All Default Filters]
     */

    const getAllDefaultFilters = async (val) => {
        setLoader(true);
        const res = await getAllDefaultFiltersAction();
        let status = res && res.status;
        if (status === 200) {
            let data = {};
            res.data.map((item) =>
                //  data[item.sFilterTag]= (item.sType==='allergen')?item.sDescription:item.sFilterName
                data[item.sFilterTag] = (item.sType === 'allergen') ? item.sFilterName : item.sFilterName
            );
            setPreferenceList({ ...data });
        } else {
            toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
        }
    }

    const checkPrvUrl = async (allergenData, planetData, Nutrition, vegetarianVegan, persnWell, favoriteRecipes) => {
        let prvPagePath = localStorage.getItem("prvPath");
        if (prvPagePath.includes("menu-item/")) {
            let filterOption = JSON.parse(localStorage.getItem("tempData"));
            setTempData(filterOption);
        } else {

            setTempData({
                allergen: allergenData,
                planet: planetData,
                nutrition: Nutrition,
                vegetarianVegan: vegetarianVegan,
                keto: persnWell,
                favoriteRecipes: favoriteRecipes,
                count: 1
            });
            setTimeout(function () {
            }, 1000)
            localStorage.setItem("tempData", JSON.stringify({
                allergen: allergenData,
                planet: planetData,
                nutrition: Nutrition,
                vegetarianVegan: vegetarianVegan,
                keto: persnWell,
                favoriteRecipes: favoriteRecipes,
                count: 1
            }))
        }
    }

    useEffect(() => {

        const formData = JSON.parse(localStorage.getItem('userDetails'));
        const { UserPreferences } = formData;

        const { sGlutenAllergens, sPersonalWellness, sPlanetHealth, Nutrition, sVegetarianVegan, sFavoriteRecipes } = UserPreferences;

        let allergenData = sGlutenAllergens.split(',');
        setAllergen(allergenData);

        let persnWell = sPersonalWellness.split(',');
        setKeto(persnWell);

        let planetData = sPlanetHealth.split(',');
        setPlanet(planetData);

        let vegetarianVegan = sVegetarianVegan.split(',');
        setVegetarianVegan(vegetarianVegan);

        let favoriteData = sFavoriteRecipes.split(',');
        setFavorites(favoriteData);

        setForm(formData);
        setNutrition(Nutrition);
        checkPrvUrl(allergenData, planetData, Nutrition, vegetarianVegan, persnWell, favoriteData)
        getAllDefaultFilters();
        getRestaurantsList();
        setTimeout(function () {
            if (!$(".calory_filter").find('li').html()) {
                setNoFilter(true)
            }
        }, 1000)
    }, []);


    useEffect(() => {
        if (tempData.count) {
            getRestaurantsMenuList();  // this is a fuction which calls api
        }
    }, [tempData])

    /* Get Restaurants Menu List List */
    const getRestaurantsMenuList = async (val) => {

        

        setLoader(true);
        let sendData = {};
        let favorites = false;
        if (val === true) {
            favorites = true;
        }
        setIsFavorites(favorites);


        sendData['FilterPreferences.nutrition.calories'] = (tempData.nutrition.calories) ? tempData.nutrition.calories : "0-1250";
        sendData['FilterPreferences.nutrition.fat'] = (tempData.nutrition.fat) ? tempData.nutrition.fat : "0-50";
        sendData['FilterPreferences.nutrition.carbs'] = (tempData.nutrition.carbs) ? tempData.nutrition.carbs : '0-120';
        sendData['FilterPreferences.nutrition.sodium'] = (tempData.nutrition.sodium) ? tempData.nutrition.sodium : "0-2000";
        sendData['FilterPreferences.nutrition.cholesterol'] = (tempData.nutrition.cholesterol) ? tempData.nutrition.cholesterol : '0-300';
        sendData['FilterPreferences.nutrition.fiber'] = (tempData.nutrition.fiber) ? tempData.nutrition.fiber : "0-25";
        sendData['FilterPreferences.nutrition.saturatedFat'] = (tempData.nutrition.saturatedFat) ? tempData.nutrition.saturatedFat : "0-10";
        sendData['FilterPreferences.nutrition.protein'] = (tempData.nutrition.protein) ? tempData.nutrition.protein : '0-50';
        sendData['FilterPreferences.nutrition.sugar'] = (tempData.nutrition.sugar) ? tempData.nutrition.sugar : '0-60';
        sendData['FilterPreferences.sGlutenAllergens'] = (tempData.allergen.length) ? tempData.allergen.join(',') : undefined;
        sendData['FilterPreferences.sVegetarianVegan'] = (tempData.vegetarianVegan.length) ? tempData.vegetarianVegan.join(',') : undefined;
        sendData['FilterPreferences.sPersonalWellness'] = (tempData.keto.length) ? tempData.keto.join(',') : undefined;
        sendData['FilterPreferences.sPlanetHealth'] = (tempData.planet.length) ? tempData.planet.join(',') : undefined;
        sendData['FilterPreferences.sFavorites'] = (tempData.favoriteRecipes.length) ? tempData.favoriteRecipes.join(',') : undefined;
        sendData['FilterPreferences.bFavorites'] = favorites;
        const res = await getRestaurantsByIdMenuListAction(iMenuId, sendData, favorites);
        let status = res && res.status;
        if (status === 200 || status === 500 && res.Message === undefined) {
            if (res.data[0]) {
                parantFun(res.data[0]['Sections']);
            }
            setLoader(false);
        } else {
            setLoader(false);
            toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
        }
    }
    let st = true;
    return (
        <div className={`${className} col-sm-12`}>
            {/*<h3>Personalized for <GetFirstName /></h3>*/}
            <div className="sidebar_innerwrap">
                <div className="sidebar_inner_recipes fullwidth">
                    <div className="sidebar_sinle_level">
                        <h4>Personalized for <GetFirstName /> <div className="editlink recipes"><a className="pink_clr_recipes" onClick={e => window.location.href = '/dashboard'}>Edit Preferences</a></div></h4>
                        {/*<div className="filter-text">Click filters to disable / re-enable.</div>*/}
                        
                        <ul className="calory_filter recipes">
                            
                            {nutrition && Object.keys(nutrition).map((item, i) => (
                                nutrition[item] !== "" && nutritionRange[item] !== nutrition[item] &&
                                <li key={i}>
                                    <div className={
                                        Object.keys(tempData['nutrition']).indexOf(item) >= 0 ? 'pink_bg event event-filter-disabl' : 'gray_bg event event-filter-re-enable'}
                                        onClick={() => removepreFrence(item, 'nutrition', nutrition[item])
                                        }
                                    >
                                        <span>{(preferenceList[item]) ? preferenceList[item] : item} {nutrition[item]}</span>
                                    </div>
                                </li>
                            )
                            )}
                            {allergen.map((w, i) => {
                                if (w !== '') {
                                    return (<li key={i}>
                                        <div className={tempData['allergen'].indexOf(w) >= 0 ? 'pink_bg event event-filter-disabl' : 'gray_bg event event-filter-re-enable'} onClick={() => removepreFrence(w, 'allergen')}>
                                            <span>{(preferenceList[w]) ? preferenceList[w] : w}</span>
                                        </div>
                                    </li>)
                                }
                            })}
                            {vegetarianVegan.map((v, i) => {
                                if (v !== '') {
                                    return (<li key={i}>
                                        <div className={tempData['vegetarianVegan'].indexOf(v) >= 0 ? 'pink_bg event event-filter-disabl' : 'gray_bg event event-filter-re-enable'} onClick={() => removepreFrence(v, 'vegetarianVegan')}>
                                            <span>{(preferenceList[v]) ? preferenceList[v] : v}</span>
                                        </div>
                                    </li>)
                                }
                            })}
                            {keto.map((k, i) => {
                                if (k !== '') {
                                    return (<li key={i}>
                                        <div className={tempData['keto'].indexOf(k) >= 0 ? 'pink_bg event event-filter-disabl' : 'gray_bg event event-filter-re-enable'} onClick={() => removepreFrence(k, 'keto')}>
                                            <span>{(preferenceList[k]) ? preferenceList[k] : k}</span>
                                        </div>
                                    </li>)
                                }
                            })}
                            {planet.map((p, i) => {
                                if (p !== '') {
                                    return (<li key={i}>
                                        <div className={tempData['planet'].indexOf(p) >= 0 ? 'pink_bg event event-filter-disabl' : 'gray_bg event event-filter-re-enable'} onClick={() => removepreFrence(p, 'planet')}>
                                            <span>{(preferenceList[p]) ? preferenceList[p] : ''}</span>
                                        </div>
                                    </li>)
                                }
                            })}
                            {notFilter &&
                                <li><div className="noFiltter">None Selected</div></li>
                            }
                        </ul>


                        {isFavorites == true && (
                            console.log('FAVORITE_RECIPES', isFavorites),
                            <>
                                <div className="continue_search_landing event active favorite_recipes" onClick={e => getRestaurantsMenuList(false)}>All Recipes</div>&nbsp;&nbsp;
                                <div className="continue_search_landing inactive favorite_recipes">Favorite Recipes</div>
                            </>
                        )}

                        {isFavorites == false && (
                            console.log('FAVORITE_RECIPES', isFavorites),
                            <>
                                <div className="continue_search_landing inactive favorite_recipes">All Recipes</div>&nbsp;&nbsp;
                                <div className="continue_search_landing event active favorite_recipes" onClick={e => getRestaurantsMenuList(true)}>Favorite Recipes</div>
                            </>
                        )}

                        {/*<div className="continue_search_landing event active" onClick={e => { window.location.replace('/restaurants-list?' + new Date().getTime()); }}>Restaurants</div>&nbsp;&nbsp;&nbsp;*/}
                        {/*<div className="continue_search_landing event inactive" >Recipes</div>*/}

                    </div>

                    <div className='locationDesktop'>
                        {restaurantsList.length > 0 &&
                            <div className="sidebar_sinle_level other_nearby_locations">
                                <h4>Other Nearby Locations</h4>
                                {restaurantsList && restaurantsList.map((item, index) => (
                                    <ul key={index}>
                                        <li className="other_locations" onClick={e => window.location.href = `/restaurant/${item.iLocationID}/${item.iMenuID}`}>
                                            <p className='restaurantName'><strong>{item.sAddress}</strong></p>
                                            <div className='restaurantDtls'>
                                                <p>{item.sCity}, {item.sState}  {item.sZip}</p>
                                                <p>Distance: {item.dDistance} miles</p>
                                            </div>
                                        </li>
                                    </ul>
                                )
                                )}
                            </div>
                        }
                    </div>
                    
                    <div className='mobileRestaurantAddress'>
                        <h4>Other Nearby Locations</h4>
                        <div>
                            {restaurantsList.length > 0 &&
                                <>
                                    {restaurantsList && restaurantsList.map((item, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-header" id={"headingOne"+index}>
                                            <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target={"#popabout"+index} aria-expanded="true" aria-controls={"popabout"+index}>{item.sAddress}</button></h5>
                                        </div>
                                        <div id={"popabout"+index} className="collapse accordian_inner" aria-labelledby={"headingOne"+index} data-parent="#popaccordion">
                                            <div className="card-body">
                                                <p>{item.sCity}, {item.sState}  {item.sZip}</p>
                                                <p>Distance: {item.dDistance} miles</p>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                    )}
                                </>
                            }
                        </div>
                    </div>

                </div>

                {(filterButton === 'menuItem') &&
                    <button type="button" className="btntype3 pink_btn btn btn-secondary" onClick={addMyMealButton}>ADD TO MYMEAL</button>
                }
            </div>
        </div>

    );
}

export default withLocalize(withRouter(SidebarFilter));
