import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {UpdateUserAction} from '../FrontEnd/Actions/User.action';
import { appConstants } from '../_Constants';
import toaster from '../_Common/Toaster';
import {ConfirmModalComponent} from '../_Common/Modal';
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
const Favorite = (props) => {

  	const [formData,setFormData] = useState({});
  	const [tempData,setTempData] = useState([]);
  	const [tempDataNew,setTempDataNew] = useState([]);
  	const [buttonActive,setButtonActive] = useState('false');
    const [loader,setLoader] = useState('false');
    const [radioButton,setRadioButton] = useState([]);
	const [modal,setModal] = useState(false);
	const [messageConfim,setMessageConfim] = useState("Saved to Favorite Restaurants!");
	const [message,setMessage] = useState("Saved to Favorite Restaurants!");
	const [confirmStatus,setConfirmStatus] = useState(false);
	const [clientName,setClientName] = useState(false);

	/* Set favorite data in the form  */
	const onFavorite=(e, field, name)=>{
		if(tempData.indexOf(props.iClientID+"")!=-1){
			unFavorites(e, field, name);
			return true;
		}
		//e.preventDefault();
		// if(!modal){
		// 	setConfirmStatus(field);
		// 	setClientName(name);
		// 	setMessageConfim("Saved to Favorite Restaurants!")
		// 	setMessage("Added to Favorites!")
		// 	setModal(true);
		// }else{
			setModal(false);
		    const formData = JSON.parse(localStorage.getItem('userDetails'));
		    let arrPref1 =[];
		    if(formData.UserPreferences.sFavorites){
		        arrPref1 = formData.UserPreferences.sFavorites.split(",")
		    }
			// let arrPref1 = tempData;
			let status = e.currentTarget.dataset.status;
			if(status==='noActive' || arrPref1.indexOf(field)===-1){
			  arrPref1.push(field+"");
			}else if(status=='active' || arrPref1.indexOf(field)>=0){
			  arrPref1.splice(arrPref1.indexOf(field),1);
			}
			//const tempDataN = [...new Set(arrPref1)]
			// setTempData([...tempDataN]);
			setTempData(arrPref1);
			UpdateDataSubmit(arrPref1);
		//}
	}

	/**
	 * [unFavorites]
	 * @param  {[type]} e  [element object]
	 * @param  {String} id [client id]
	 */
	const unFavorites = (e,id='',name='') => {
		//e.preventDefault();
        if (!modal) {
			setConfirmStatus(id);
			setClientName(name);
			setMessageConfim('Remove '+name+' from My Favorites?')
			setMessage('Successfully removed from favorites.')
			setModal(true);
		}else{
			setModal(false);
			let {UserPreferences} = formData;
			let {sFavorites} = UserPreferences;
			let arr = sFavorites.split(',');
			arr.splice(arr.indexOf(id+""),1);
			// arr = arr.join(',');
			formData['UserPreferences']['sFavorites'] = arr.join(',');
			setFormData(formData);
			setTempData(arr);
			UpdateDataSubmit(arr);
		 }
	}

	/**

	/* Call edit api */
    const UpdateDataSubmit = async (arr) => {
      setButtonActive('true');
      let sendData = {};
      sendData = formData;
      sendData['eLoginProvider'] = formData['sLoginProvider'];
      sendData['UserPreferences']['sFavorites'] = arr.join(',');
      const res = await UpdateUserAction(sendData);
      let status = res && res.status;
      if(status === 200){
           setButtonActive('false');
           sendData['UserPreferences'] = res.data[0]['UserPreferences'];
           localStorage.setItem("userDetails",JSON.stringify(sendData));
           toaster.show({message: message, intent: "success"});
      }else{
           toaster.show({message: appConstants.RESPONSECODE[status], intent: "danger"});
      }
    }

  	useEffect(() => {
	      const formData = JSON.parse(localStorage.getItem('userDetails'));
		  const {UserPreferences} = formData;
		  const {sFavorites} = UserPreferences || '';
		  let arr = [];
		  if(sFavorites !== ''){
		  	  arr = sFavorites.split(',');
		  }
		  setFormData(formData);
		  setTempData(arr);
	}, []);

//Remove from Favorites?
	/**
	 * [Confirm Modal Component Control]
	 * @return -
	 */
	const toggle=()=> {
		setConfirmStatus(false);
		setConfirmStatus(false);
		setModal(!modal);
	}

	/**
	 * [Confirm Modal Component]
	 */

	const modalData = (<ConfirmModalComponent
								 buttonLabel="Click"
								 formData={messageConfim}
								 formTitle={'Confirm'}
								 modal={modal}
								 toggle={toggle}
								 okButton={{'label':'Yes','function':e=>onFavorite(e,confirmStatus)}}
								/>);

	return (
		<>
		<div id="fav1"
		data-id={props.iClientID}
		className={`${tempData.indexOf(props.iClientID+"")!=-1?'active':''} fav_sec favrout_sec` }
		data-status={tempData.indexOf(props.iClientID+"")!=-1?"active":"noActive"}
		onClick={e=>onFavorite(e, props.iClientID, props.sClientName)} >
		    <img src="/static/img/heartempty.png" alt="heartempty" />
		</div>
		{modalData}
		</>
	);
}
export default withLocalize(withRouter(Favorite));
