import {postRequest, getRequest} from "../../_Api/helper"

export const userRegistrationAction = (data) => {
    return postRequest('api/User/CreateUserPost',data);
}

export const resetPasswordPostAction = (data) => {
    return postRequest('api/User/ResetPasswordPost',data);
}

export const checkEmailAction = (data) => {
    return getRequest('api/User/CheckEmail',data);
}

export const verifyEmailAction = (data) => {
    return getRequest('api/User/VerifyEmail',data);
}

export const getManualProviderKeyAction = (data) => {
    return getRequest('api/User/GetManualProviderKey',data);
}

export const getUserAction = (data) => {
    return getRequest('api/User/GetUser',data);
}

export const UpdateUserAction = (data) => {
      return postRequest('api/User/UpdateUser',data);
}

export const getAllFavoritesAction = (data) => {
    return getRequest('api/User/GetUserFavorites',data);
}

export const getAllFavoriteRecipesAction = (data) => {
    return getRequest('api/User/GetUserFavoriteRecipes', data);
}

export const getLog = (data) => {
    return getRequest('api/Logs/GetLog/1');
}

export const addLog = (data) => {
    return postRequest('api/Logs/AddLog',data);
}
