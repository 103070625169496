import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { checkEmailAction, userRegistrationAction } from '../../Actions/User.action';
import { sendEmailAction } from '../../Actions/Utility.action';
import toaster from '../../../_Common/Toaster';
import SocialLoginPage from '../../../_Common/SocialLoginPage';
import { appConstants, mailTemplate } from '../../../_Constants';
import { FormError, ShowFormErrors, ShowFormValidErrors } from "../../../_Common/FormError";
import ButtonLoader from '../../../_Common/ButtonLoader';
import './RegistrationPage.css';
import { UpdateUserAction } from '../../Actions/User.action';
import HeaderLoginPage from '../HeaderLoginPage/HeaderLoginPage';
import HeaderMicrosoftLoginPage from '../MicrosoftPage/HeaderMicrosoftLoginPage/HeaderMicrosoftLoginPage';
import FooterMicrosoftLoginPage from '../MicrosoftPage/FooterMicrosoftLoginPage/FooterMicrosoftLoginPage';


const RegistrationPage = ({ history }) => {

    const [signupForm, setSignupForm] = useState({ sCountryCode: '+1' });
    const [formErrors, setFormErrors] = useState({});
    const { register, errors, getValues, handleSubmit, reset } = useForm();
    const [fieldName, setFieldName] = useState();
    const [buttonActive, setButtonActive] = useState('false');
    const [validatePasswordPattern, setValidatePasswordPattern] = useState(false)
    const emailRef = useRef()


    /* Set the value in the signupForm object */
    const signupFormHandler = (e, field) => {
        e.preventDefault();
        let signupFormData = signupForm;
        signupFormData[field] = e.target.value;
        let passwordValidate = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (field == "sPassword" && !passwordValidate.test(signupFormData.sPassword)) {
            setValidatePasswordPattern(true)
        }
        if (field == "sPassword" && passwordValidate.test(signupFormData.sPassword)) {
            setValidatePasswordPattern(false)
        }
        if (field == "sConfirmPassword" && !passwordValidate.test(signupFormData.sPassword)) {
            setValidatePasswordPattern(true)
            return true;
        }
        setSignupForm(signupFormData);
        setFieldName(field);
    }

    /*submit data into database */
    const submitForm = async (provider, data) => {
        let isValidEmail = await checkEmail();
        if (isValidEmail) {
          // submit the form
            setButtonActive('true');
            let signupForm1 = signupForm;
            signupForm1['eLoginProvider'] = 'Manual';
            signupForm1['sProviderKey'] = '';
            let passwordValidate = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            if (passwordValidate.test(signupForm1.sPassword)) {
                let actionProcess = document.getElementsByClassName('actionProcess')[0];
                if(actionProcess) {
                    actionProcess.style.display = "block";
                }
                let res = await userRegistrationAction(signupForm1);
                let status = res && res.status;
                console.log("status", status)
                if (status === 200) {
                    // toaster.show({message: appConstants.MSGSTATUS.ADD, intent: "success"});
                    /* call the api to send email*/
                    //if(res && res.data && res.data[0]){
                    setButtonActive('false');
                    //await sendEmailFun(res.data[0]);
                    //}
                    //alert('Your registration is complete. Please sign in.');
                    toaster.show({ message: "Your registration is complete and you will automatically be logged in.", intent: "success" });
                    reset();
                    setSignupForm({ sCountryCode: '+1' });
                    let data = res.data && res.data[0];
                    let { UserPreferences } = data;
                    if (UserPreferences === null || UserPreferences === undefined) {
                        data["UserPreferences"] = {
                            "Nutrition": {
                                "calories": "0-1250",
                                "fat": "0-50",
                                "carbs": "0-120",
                                "sodium": "0-2000",
                                "cholesterol": "0-300",
                                "fiber": "0-25",
                                "saturatedFat": "0-10",
                                "protein": "0-50",
                                "sugar": "0-60"
                            },
                            "sGlutenAllergens": "",
                            "sVegetarianVegan": "",
                            "sPersonalWellness": "",
                            "sCustomFilters": "",
                            "sPlanetHealth": "",
                            "sFavorites": "",
                            "sFavoriteRecipes": "",
                            "sFavoriteMenuItems": "",
                            'iNutritionCount': 0,
                            'iAllergenCount': 0,
                            'iVegetarianVeganCount': 0,
                            'iPersonalWellnessCount': 0,
                            'iPlantHealthCount': 0
                        }

                    }
                    data.eLoginProvider = data.sLoginProvider;
                    localStorage.setItem("userDetails", JSON.stringify(data));

                    let userDetailsData = JSON.parse(localStorage.getItem('userDetails'));

                    if(userDetailsData.sUserName.includes("@microsoft.com")) {
                        localStorage.setItem("userMSPilot", true);
                    }

                    let res2 = await UpdateUserAction(userDetailsData);
                    let status = res2 && res2.status;
                    if (status === 200) {
                        userDetailsData['UserPreferences'] = res2.data[0]['UserPreferences'];
                        //setFormData({
                        //    iNutritionCount: 0,
                        //    iAllergenCount: 0,
                        //    iVegetarianVeganCount: 0,
                        //    iPersonalWellnessCount: 0,
                        //    iPlantHealthCount: 0
                        //})
                    } else {
                        toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
                    }

                    setTimeout(function () {
                        toaster.clear();
                        //history.push('/subscription');
                        if (res.data[0].sUserName == 'tripism@healthydining.biz') {
                            window.location.href = '/dashboard';
                        } else if(res.data[0].sUserName.includes('@microsoft.com')) {
                            localStorage.setItem('userMSPilot', true);
                            window.location.href = '/msdashboard';
                        }
                        else {
                            window.location.href = '/subscription';
                        }

                    }, 1000);
                    //window.location.href = '/login';

                } else {
                    setButtonActive('false');
                    if (status === 409) {
                        toaster.show({ message: "The email you entered is already being used. Please try again with a different email.", intent: "danger" });
                        //reset();
                        //setSignupForm({ sCountryCode: '+1' });
                    } else {
                        if (status == "") {
                            toaster.show({ message: "There was an error creating your account.  Please try again.", intent: "danger" });
                        }
                        else {
                            toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
                        }
                        //reset();
                        //setSignupForm({ sCountryCode: '+1' });
                    }
                }
            } else {
                alert("The password must be 6 characters, which must contain at least one numeric digit, one uppercase and one lowercase letter and contain at least one special character.");
                setButtonActive('false');
            }
    }

    /* send email function */
    const sendEmailFun = async (signupData) => {
        if (signupData.iEmailCode && signupData.sProviderKey) {
            let link = appConstants.FRONTURL + 'verify-email/' + signupData.iEmailCode + '/' + signupData.sProviderKey;

            var res = (mailTemplate.VERIFICATION_TEMP).replace("#link", '<a style="color: #ef2859" target="_blank" href="' + link + '">EMAIL VERIFICATION LINK</a>');
            res = (mailTemplate.TEMPLATE).replace("#conetnt", res);

            let senData = {
                'sReceiver': signupData.sEmail,
                'sSubject': mailTemplate.VERIFICATION_SUB,
                'sMessage': res,
            }
            alert('An email has been sent to confirm your email address.  Please click on the EMAIL VERIFICATION LINK.');
            let resData = await sendEmailAction(senData);
            let status = resData && resData.status;

            if (status === 200) {
                setButtonActive('false');
                toaster.show({ message: appConstants.MSGSTATUS.SENDEAMIL, intent: "success" });
            } else {
                setButtonActive('false');

                if (status === 409) {
                    toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
                } else {
                    toaster.show({ message: appConstants.RESPONSECODE['409'], intent: "danger" });

                }
            }

        }
        }
        
    }

    useEffect(() => {
        document.title = "MyMenuConcierge-Registration";

        let passwordValidate = new RegExp("/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/");
        /*console.log("signupForm1", passwordValidate.test("a1aAcd@#58"))*/

        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');

        togglePassword.addEventListener('click', function (e) {
            // toggle the type attribute
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
            // toggle the eye / eye slash icon
            this.classList.toggle('bi-eye');
        });

        const toggleConfirmPassword = document.querySelector('#toggleConfirmPassword');
        const confirmpassword = document.querySelector('#confirmpassword');

        toggleConfirmPassword.addEventListener('click', function (e) {
            // toggle the type attribute
            const type = confirmpassword.getAttribute('type') === 'password' ? 'text' : 'password';
            confirmpassword.setAttribute('type', type);
            // toggle the eye / eye slash icon
            this.classList.toggle('bi-eye');
        });

    }, []);

    var checkEmail = async () =>{
       let email = signupForm.sEmail;
       let res = await checkEmailAction({sEmail : email});
       if(res.data && res.data[0] && res.data[0].sEmail == email)
       {
            setFormErrors({"sEmail" : "Email already exists"});
            return false;
       } else{
            setFormErrors({"sEmail" : ""});
       }
       return true;
    }

    return (
        <div className="App">
            <header className="App-header">
                <div className="landing_wraper ">
                
                {(window.location.hostname.includes("microsoft") || window.location.hostname.includes("mstravel")) ? <HeaderMicrosoftLoginPage/>: <HeaderLoginPage/>}

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <div className="screen2_inner">
                                    <img src="/static/img/landinglogo.png"  alt="landinglogo" />
                                </div> */}
                                <div className="common_sec">
                                    <div className="common_secinner">
                                        <h1>Create Your MyMenu Account</h1>
                                        <div className="form_subtext">Have an existing account? <a className='microsoftBlueColor' onClick={e => window.location.href = '/login'}>Login</a></div>
                                    </div>
                                </div>

                                <div className="common_form_sec register_form">
                                    <div className="common_form_secinner">
                                        {/*<div className="back_btn" onClick={() => window.location.href = '/login'}>
                             <a href=""><img src="/static/img/backicon.png" /></a>
                          </div>*/}

                                        <form onSubmit={handleSubmit(submitForm)}>

                                            {ShowFormValidErrors(errors, formErrors, fieldName) &&
                                                <div className="form_error">{ShowFormValidErrors(errors, formErrors, fieldName)}</div>
                                            }

                                            <FormGroup className="namefieds">
                                                {/*<img src="static/img/usericon.png" className="ico-inpu" />*/}
                                                <label for="sFirstName" className="form-label">First Name*</label>
                                                <input type="text"
                                                    name="sFirstName"
                                                    id="sFirstName"
                                                    className="form-control name_field"
                                                    onChange={e => signupFormHandler(e, 'sFirstName')}
                                                    placeholder=""
                                                    ref={register({
                                                        required: "!",
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sFirstName")}</div>
                                            </FormGroup>

                                            <FormGroup className="namefieds">
                                                {/*<img src="static/img/usericon.png" className="ico-inpu" />*/}
                                                <label for="sLastName" className="form-label">Last Name*</label>
                                                <input
                                                    type="text"
                                                    name="sLastName"
                                                    id="sLastName"
                                                    className="form-control lLastname_field"
                                                    onChange={e => signupFormHandler(e, 'sLastName')}
                                                    placeholder=""
                                                    ref={register({
                                                        required: "!",
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sLastName")}</div>
                                            </FormGroup>

                                            <FormGroup>
                                                {/*<img src="static/img/emailicon.png" className="ico-inpu" />*/}
                                                <label for="sEmail" className="form-label">Email*</label>
                                                <input type="email"
                                                    name="sEmail"
                                                    id="sEmail"
                                                    onChange={e => signupFormHandler(e, 'sEmail')}
                                                    className="form-control plac-im"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    onBlur={checkEmail}
                                                    ref={register({
                                                        required: "!",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sEmail")}</div>
                                            </FormGroup>

                                            <FormGroup>
                                                {/*<img src="static/img/emailicon.png" className="ico-inpu" />*/}
                                                <label for="sConfirmEmail" className="form-label">Confirm Email*</label>
                                                <input type="email"
                                                    name="sConfirmEmail"
                                                    id="sConfirmEmail"
                                                    onChange={e => signupFormHandler(e, 'sConfirmEmail')}
                                                    className="form-control plac-im"
                                                    placeholder=""
                                                    autoComplete="off"
                                                    ref={register({
                                                        required: "!",
                                                        validate: {
                                                            matchesPreviousEmail: value => {
                                                                const { sEmail } = getValues();
                                                                return sEmail === value || "Emails should match!";
                                                            }
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sConfirmEmail")}</div>
                                            </FormGroup>

                                            <FormGroup>
                                                <label for="sPhoneNumber" className="form-label-phone">Phone Number*</label>
                                                <div className="phonr_field">
                                                    <select className="form-control" name="sCountryCode" id="sPhoneNumber" onChange={e =>
                                                        signupFormHandler(e, 'sCountryCode')}>
                                                        <option value="+1">+1</option>
                                                    </select>
                                                    <input type="tel"
                                                        name="sPhoneNumber"
                                                        onChange={e => signupFormHandler(e, 'sPhoneNumber')}
                                                        className="form-control"
                                                        maxLength="10"
                                                        placeholder="Phone number"
                                                        ref={register({
                                                            required: "!",
                                                            pattern: {
                                                                value: /^[-+]?[0-9]+$/,
                                                                message: "Please enter number"
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: "Phone number fields should have 10 digits"
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: "Phone number fields should have 10 digits"
                                                            },
                                                        })}
                                                    />
                                                    <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sPhoneNumber")}</div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <label for="sPostalCode" className="form-label">Postal Code</label>
                                                    <input type="zip"
                                                        name="sPostalCode"
                                                        id="sPostalCode"
                                                        onChange={e => signupFormHandler(e, 'sPostalCode')}
                                                        className="form-control"
                                                        maxLength="5"
                                                        placeholder="Postal code"
                                                        ref={register({
                                                            // required: "!",
                                                            pattern: {
                                                                value: /\d{5}/,
                                                                message: "Please enter number"
                                                            },
                                                            maxLength: {
                                                                value: 5,
                                                                message: "Postal code should have 5 digits"
                                                            },
                                                            minLength: {
                                                                value: 5,
                                                                message: "Postal code should have 5 digits"
                                                            },
                                                        })}
                                                    />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sPostalCode")}</div>
                                            </FormGroup>

                                            <FormGroup>
                                                {/*<img src="static/img/lock.png" className="ico-inpu" />*/}
                                                <label for="password" className="form-label">Password*</label>
                                                <input
                                                    type="password"
                                                    name="sPassword"
                                                    id="password"
                                                    onChange={e => signupFormHandler(e, 'sPassword')}
                                                    className="form-control"
                                                    placeholder=""

                                                    ref={register({ required: "!" })}
                                                />
                                                <i className="bi bi-eye-slash" id="togglePassword"></i>

                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sPassword")}</div>
                                                {validatePasswordPattern &&
                                                    <div className="text-danger" xs="12" md="6"><span title="The password must be 6 characters, which must contain at least one numeric digit, one uppercase and one lowercase letter and contain at least one special character.">!</span></div>

                                                }
                                            </FormGroup>

                                            <FormGroup>
                                                {/*<img src="static/img/lock.png" className="ico-inpu" />*/}
                                                <label for="confirmpassword" className="form-label">Confirm Password*</label>
                                                <input
                                                    type="password"
                                                    name="sConfirmPassword"
                                                    id="confirmpassword"
                                                    onChange={e => signupFormHandler(e, 'sConfirmPassword')}
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={register({
                                                        required: "!",
                                                        validate: {
                                                            matchesPreviousPassword: value => {
                                                                const { sPassword } = getValues();
                                                                return sPassword === value || "Passwords should match!";
                                                            }
                                                        }
                                                    })}
                                                />
                                                <i className="bi bi-eye-slash" id="toggleConfirmPassword"></i>
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sConfirmPassword")}</div>
                                            </FormGroup>
                                            <br />
                                            <p>*Required fields</p>
                                            <p>The password must:
                                                <ul>
                                                    <li>be at least 6 characters long</li>
                                                    <li>contain at least one numeric digit</li>
                                                    <li>contain one uppercase and one lowercase letter</li>
                                                    <li>contain at least one special character (!@#\$%\^&\*)</li>
                                                </ul>
                                            </p>
                                            <div className="continue_search_result">
                                                
                                                {/*<Button className="btntype3 pink_btn">CREATE ACCOUNT</Button> */}
                                                <ButtonLoader actions={[
                                                    {
                                                        'title': 'LOGIN',
                                                        'loading': 'Loading...',
                                                        'loadingactive': buttonActive,
                                                        'onClick': e => function () { return true },
                                                        'data-type': "CREATE ACCOUNT",
                                                        "className": "continue_search_result"
                                                    },
                                                ]} />
                                            </div>

                                            {!(window.location.hostname.includes("microsoft") || window.location.hostname.includes("mstravel")) && <SocialLoginPage />}

                                            <p>If you need help signing up please click the <img className="chat_icon_small" src="/static/img/GSI_Red_Grey_Chat.png" alt="GSI_Red_Grey_Chat"/> icon.</p>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
            {(window.location.hostname.includes("microsoft") || window.location.hostname.includes("mstravel")) && <FooterMicrosoftLoginPage/>}

        </div>
    );
}
export default withLocalize(withRouter(RegistrationPage));
