import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import toaster from './Toaster';
import { appConstants, mailTemplate } from '../_Constants';
import { OldSocialLogin as SocialLogin } from 'react-social-login';
import { userRegistrationAction, getUserAction } from '../FrontEnd/Actions/User.action';
import { sendEmailAction } from '../FrontEnd/Actions/Utility.action';


const SocialLoginPage = ({history}) => {

  const [signupForm,setSignupForm] = useState({});

  /*submit data into databse */
  const setUserInfoRedirect = async (userData)=>{
    if(userData && userData.status === 200){
        let data = userData.data && userData.data[0];
        let {UserPreferences} =  data;
         if(UserPreferences===null || UserPreferences===undefined){
           data["UserPreferences"] = {
              "Nutrition": {
                "calories": "0-1250",
                "fat": "0-50",
                "carbs": "0-120",
                "sodium": "0-2000",
                "cholesterol": "0-300",
                "fiber": "0-25",
                "saturatedFat": "0-10",
                "protein": "0-50",
                "sugar": "0-60"
              },
              "sGlutenAllergens": "",
              "sVegetarianVegan": "",
              "sPersonalWellness": "",
              "sCustomFilters": "",
              "sPlanetHealth": "",
               "sFavorites": "",
               "sFavoriteRecipes": "",
               "sFavoriteMenuItems": "",
              'iNutritionCount':0,
              'iAllergenCount':0,
              'iVegetarianVeganCount':0,
              'iPersonalWellnessCount':0,
              'iPlantHealthCount':0
            }
         }
        localStorage.setItem("userDetails",JSON.stringify(data));
      }
       setTimeout(function(){
           toaster.clear();
           //history.push('/dashboard');
           window.location.href = '/dashboard';
       },1000);
  } 
  const submitForm = async (provider, data) =>{

     let signupForm1 = signupForm;
     signupForm1['eLoginProvider'] = provider;
     signupForm1['sProviderKey'] = data && data.id;
     signupForm1['sEmail'] = data && data.email;
     signupForm1['sFirstName'] = data && data.firstName;
     signupForm1['sLastName'] = data && data.lastName;

     let res = await userRegistrationAction(signupForm1);
     let status = res && res.status;

     if(status === 200){
         toaster.show({message: appConstants.MSGSTATUS.ADD, intent: "success"});


         setUserInfoRedirect(res)
         /* call the api to send email*/
         if(res && res.data && res.data[0]){
            await sendEmailFun(res.data[0]);
         }
         setSignupForm({});

     }else if(status === 409){
         /* Call user api to save user detail*/
         if(res && res.data){
           let userParams = {
             eLoginProvider:provider,
             sProviderKey: data && data.id
           }
           const userData = await getUserAction(userParams);
           if(userData && userData.status === 200){
               setUserInfoRedirect(userData)
           }
         }
     }else{
         toaster.show({message: appConstants.RESPONSECODE[status], intent: "danger"});
     }
   }

   /* send email function */
    const sendEmailFun = async(signupData)=>{
      if(signupData.iEmailCode && signupData.sProviderKey){
         let link = appConstants.FRONTURL+'verify-email/'+signupData.iEmailCode+'/'+signupData.sProviderKey;
         var res = (mailTemplate.VERIFICATION_TEMP).replace("#link", "<a target='_blank' href='"+link+"'>Click here</a>");

         let senData = {
           'sReceiver': signupData.sEmail,
           'sSubject': appConstants.MSGSTATUS.REGISTRATION,
           'sMessage': res,
         }
         await sendEmailAction(senData);

         // let link = appConstants.FRONTURL+'login';
         //
         // var res = (mailTemplate.WELCOME_TEMPL).replace("#link", '<a style="color: #ef2859" target="_blank" href="'+link+'">LOGIN LINK</a>');
         //     res = (res).replace("#name", signupData.sFirstName);
         //     res = (mailTemplate.TEMPLATE).replace("#conetnt", res);
         //
         // let senData = {
         //   'sReceiver': signupData.sEmail,
         //   'sSubject': mailTemplate.WELCOME_SUB,
         //   'sMessage': res,
         // }
         //
         // await sendEmailAction(senData);

      }
    }


  /* Register with social login */
  const handleSocialLogin = async(user, err) => {
      document.getElementsByClassName('actionProcess')[0].style.display = "block";
      if (user) {
          submitForm(user._provider, user._profile)
      }
      else {
          document.getElementsByClassName('actionProcess')[0].style.display = "none";

      }
  }

  useEffect(()=>{

  },[]);

  return (
    <div className="sign_up_usingsec">
       <p>or sign in using</p>
       <div className="social_login">
         <SocialLogin
            provider='facebook'
            appId={appConstants.FACEBOOK_APP_ID}
            callback={handleSocialLogin}
          >
           <a ><i className="fa fa-facebook" aria-hidden="true"></i></a>
         </SocialLogin>

         <SocialLogin
          provider='google'
          appId={appConstants.GOOGLE_CLIENT_ID}
          callback={handleSocialLogin}
        >
         <a ><i className="fa fa-google-plus" aria-hidden="true"></i></a>
        </SocialLogin>
       </div>
    </div>
  );
}

export default withLocalize(withRouter(SocialLoginPage));
